import React, { useState, useEffect } from 'react';
import axios from 'axios';
import Swal from 'sweetalert2';
import '../../css/structure.css';
import jsPDF from "jspdf";
import "jspdf-autotable";
import * as XLSX from "xlsx";

const chargement = require("../images/loadingcarre.gif");
const telechargerModal = require("../images/telechargerModal.png");

const Itableindicateur = () => {
  const [sousDomaines, setSousDomaines] = useState([]);
  const [selectedSousDomaine, setSelectedSousDomaine] = useState('');
  const [annee, setAnnee] = useState([]);
  const [selectedAnnee, setSelectedAnnee] = useState('');
  const [nomecole, setNomecole] = useState([]);
  const [selectedNomecole, setSelectedNomecole] = useState('');
  const [niveauecole, setNiveauecole] = useState([]);
  const [selectedNiveauecole, setSelectedNiveauecole] = useState('');
  const [factacessData, setFactacessData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [searchTerm, setSearchTerm] = useState('');
  const [format, setFormat] = useState("");

  // Charger les données pour les options de filtres
  useEffect(() => {
    axios.get('https://precvadithies.sn/sous-domaine').then(response => setSousDomaines(response.data));
    axios.get('https://precvadithies.sn/annee').then(response => setAnnee(response.data));
    axios.get('https://precvadithies.sn/ecole').then(response => {
      setNomecole(response.data);
      setNiveauecole(response.data);
    });
  }, []);

  // Charger toutes les données par défaut
  useEffect(() => {
    setLoading(true);
    axios.get('https://precvadithies.sn/insert-structure')
      .then((response) => {
        setFactacessData(response.data);
        setLoading(false);
      })
      .catch((error) => {
        console.error('Erreur lors du chargement des données:', error);
        setLoading(false);
        setFactacessData([]);
      });
  }, []);

  // Charger les données filtrées si les filtres sont appliqués
  useEffect(() => {
    if (selectedSousDomaine || selectedNomecole || selectedNiveauecole || selectedAnnee) {
      setLoading(true);
      const url = `https://precvadithies.sn/insert-structure?get=${selectedSousDomaine}&ecole=${selectedNomecole}&niveau=${selectedNiveauecole}&annee=${selectedAnnee}`;
      axios.get(url)
        .then((response) => {
          console.log("factacessData ici");
          console.log(factacessData);
          
          setFactacessData(response.data);
          setLoading(false);
        })
        .catch((error) => {
          console.error('Erreur lors de la récupération des données filtrées:', error);
          setLoading(false);
          setFactacessData([]);
        });
    }
  }, [selectedSousDomaine, selectedNomecole, selectedNiveauecole, selectedAnnee]);



  const handleDelete = (id) => {
    Swal.fire({
      title: 'Confirmer la suppression?',
      text: 'Cette action est irréversible!',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Oui, supprimer!',
      cancelButtonText: 'Annuler',
    }).then((result) => {
      if (result.isConfirmed) {
        axios.delete(`https://precvadithies.sn/insert-structure/${id}`)
          .then(() => {
            setFactacessData(prev => prev.filter(item => item.idfact !== id));
            Swal.fire('Supprimé!', "L'élément a été supprimé.", 'success');
          });
      }
    });
  };

  const handleEdit = (item) => {
    Swal.fire({
      title: 'Modifier la valeur',
      input: 'text',
      inputValue: item.valueStructure,
      showCancelButton: true,
      confirmButtonText: 'Enregistrer',
      cancelButtonText: 'Annuler',
    }).then((result) => {
      if (result.isConfirmed) {
        const newValue = result.value;
        axios.put(`https://precvadithies.sn/insert-structure/${item.idfact}`, { value_structure: newValue })
          .then(() => {
            setFactacessData(prev =>
              prev.map(data => (data.idfact === item.idfact ? { ...data, valueStructure: newValue } : data))
            );
            Swal.fire('Modifié!', 'La valeur a été mise à jour.', 'success');
          });
      }
    });
  };

  const handleFormatChange = (e) => {
    setFormat(e.target.value);
  };

  const downloadFile = () => {
    if (format === "pdf") downloadPDF();
    else if (format === "excel") downloadExcel();
    else alert("Veuillez sélectionner un format");
  };

  const downloadPDF = () => {
    const doc = new jsPDF();
  
    // Titre principal centré
    doc.setFontSize(16);
    doc.text(`Donnée sur l'education : ${selectedSousDomaine || `non défini`}`, 105, 20, { align: 'center' });
  
    // Sous-titres à gauche et à droite
    doc.setFontSize(12);
    doc.text(`niveau ${selectedNiveauecole  || `non défini`}`, 20, 30);
    doc.text(`Pour l'année ${selectedAnnee  || `non défini`}`, 180, 30, { align: 'right' });
  
    // Titre du tableau centré
    doc.setFontSize(14);
    doc.text(`Nom de l'etablissement : ${selectedNomecole || `non défini`}`, 105, 40, { align: 'center' });
  
    // Ajouter le tableau avec des couleurs alternées
    doc.autoTable({
      startY: 50, // Position de départ du tableau
      head: [["Valeur Indicateur", "Valeur"]],
      body: filteredData.map(item => [
        item.valeurIndicateur,
        item.valueStructure,
      ]),
      styles: {
        fillColor: [220, 220, 220], // Couleur de fond par défaut des lignes
        textColor: [0, 0, 0],
      },
      alternateRowStyles: {
        fillColor: [240, 240, 240], // Couleur de fond alternée pour les lignes impaires
      },
      headStyles: {
        fillColor: [0, 102, 204], // Couleur de fond pour l'en-tête du tableau
        textColor: [255, 255, 255],
      },
    });
  
    // Téléchargement du fichier PDF avec le nom dynamique
    doc.save(`${selectedSousDomaine}.pdf`);
  };

  const downloadExcel = () => {
    const filteredColumns = filteredData.map(({ valeurIndicateur, valueStructure }) => ({
      valeurIndicateur, valueStructure
    }));
    const worksheet = XLSX.utils.json_to_sheet(filteredColumns);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "Table");
    XLSX.writeFile(workbook, `${selectedSousDomaine}_${selectedNomecole}_${selectedNiveauecole}_${selectedAnnee}.xlsx`);
  };
  
  const filteredData = factacessData.filter((item) =>
    item.valueStructure.toLowerCase().includes(searchTerm.toLowerCase())
  );
  // Obtenir les niveaux d'école uniques
const uniqueNiveaux = Array.from(new Set(niveauecole.map(sd => sd.niveauEcole)));

  return (
    <div className="app-container">
      {loading ? (
        <p>Chargement...</p>
      ) : (
        <>
          <div style={{ display: "flex", marginBottom: "10px", marginTop: "3px", gap:"10px" }}>
            <select className='select_and_search' onChange={(e) => setSelectedSousDomaine(e.target.value)} value={selectedSousDomaine}>
              <option value="">Tous les sous-domaines</option>
              {sousDomaines.map(sd => (
                <option key={sd.valeursousdomaine} value={sd.valeursousdomaine}>
                  {sd.valeursousdomaine}
                </option>
              ))}
            </select>
            <select className='select_and_search' onChange={(e) => setSelectedNiveauecole(e.target.value)} value={selectedNiveauecole}>
              <option value="">Tous les niveaux</option>
              {[...new Set(niveauecole.map(sd => sd.niveauEcole))].map(niveau => (
                <option key={niveau} value={niveau}>
                  {niveau}
                </option>
              ))}
            </select>
            <select className='select_and_search' onChange={(e) => setSelectedNomecole(e.target.value)} value={selectedNomecole}>
              <option value="">Toutes les écoles</option>
              {nomecole.map(sd => (
                <option key={sd.nomEcole} value={sd.nomEcole}>
                  {sd.nomEcole}
                </option>
              ))}
            </select>
            <select className='select_and_search' onChange={(e) => setSelectedAnnee(e.target.value)} value={selectedAnnee}>
              <option value="">Toutes les années</option>
              {annee.map(sd => (
                <option key={sd.valeurAnnee} value={sd.valeurAnnee}>
                  {sd.valeurAnnee}
                </option>
              ))}
            </select>
            <input
              type="text"
              placeholder="Rechercher..."
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
              className='select_and_search'
            />
          </div>
          <div className="table-container">
            <table className="styled-table">
              <thead>
                <tr>
                  <th>Libellé Indicateur</th>
                  <th>Valeur</th>
                   {/* <th>Actions</th>*/}
                </tr>
              </thead>
              <tbody>
                {filteredData.length > 0 ? (
                  filteredData.map(item => (
                    <tr key={item.idfact}>
                      <td>{item.valeurIndicateur}</td>
                      <td>{item.valueStructure}</td>
                     {/*<td>
                        <button onClick={() => handleEdit(item)} className="edit-btn">Modifier</button>
                        <button onClick={() => handleDelete(item.idfact)} className="delete-btn">Supprimer</button>
                      </td>*/}
                    </tr>
                  ))
                ) : (
                  <tr>
                    <td colSpan="3" style={{ textAlign: "center" }}>Aucune ligne trouvée</td>
                  </tr>
                )}
              </tbody>
            </table>
            <div style={{ display: "flex", justifyContent:"center", alignItems:"center" }}>
              <select className='select_and_search' value={format} onChange={handleFormatChange}>
                <option value="">Choisir un format</option>
                <option value="pdf">PDF</option>
                <option value="excel">Excel</option>
              </select>
              <img
        style={{ cursor: "pointer", marginBottom: "10px" }}
        onClick={downloadFile}
        title="Télécharger"
        alt="precvadi"
        src={telechargerModal}
        fluid
        rounded
        width="60px"
        height="60px"
        id="telecharger"
      />
      <span for="telecharger" >Télécharger ici</span>
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export default Itableindicateur;
